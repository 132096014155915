import { SelectedProduct, SelectedTopping } from "models/Product";

export const cartItemsTotalPrice = (items, coupon: any = null) => {
  let total = items?.reduce?.((price, product) => {
    return price + product.total_price * product.quantity;
  }, 0);

  return total;
};

function selectedToppingsAreEqual(
  array1: SelectedTopping[] = [],
  array2: SelectedTopping[] = [],
) {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i].id !== array2[i].id || array1[i].size !== array2[i].size) {
      return false;
    }
  }

  return true;
}

const compareProducts = (
  product1: SelectedProduct,
  product2: SelectedProduct,
) => {
  return (
    product1.id === product2.id &&
    product1.size === product2.size &&
    product1.hot === product2.hot &&
    product1.ice === product2.ice &&
    product1.sugar === product2.sugar &&
    selectedToppingsAreEqual(
      product1.selectedToppings,
      product2.selectedToppings,
    )
  );
};

// cartItems, cartItemToAdd
const addItemToCart = (state, action) => {
  const { payload } = action || {};
  const existingCartItemIndex = state.items.findIndex((item: SelectedProduct) =>
    compareProducts(item, payload),
  );

  if (existingCartItemIndex > -1) {
    const newState = [...state.items];
    newState[existingCartItemIndex].quantity += action.payload.quantity;
    return newState;
  }
  return [...state.items, action.payload];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {
  return state.items.reduce((acc, item) => {
    if (compareProducts(item, action.payload)) {
      const newQuantity = item.quantity - action.payload.quantity;

      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];
    }
    return [...acc, item];
  }, []);
};

const clearItemFromCart = (state, action) => {
  return state.items.filter((item) => item.id !== action.payload.id);
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "REHYDRATE":
      return { ...state, ...action.payload };
    case "TOGGLE_CART":
      return { ...state, isOpen: !state.isOpen };
    case "ADD_ITEM":
      return { ...state, items: addItemToCart(state, action) };
    case "REMOVE_ITEM":
      return { ...state, items: removeItemFromCart(state, action) };
    case "CLEAR_ITEM_FROM_CART":
      return { ...state, items: clearItemFromCart(state, action) };
    case "CLEAR_CART":
      return { ...state, items: [] };
    case "APPLY_COUPON":
      return { ...state, coupon: action.payload };
    case "REMOVE_COUPON":
      return { ...state, coupon: null };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
