import * as React from "react"
const HeartIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#fff"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M10 16.875S2.189 12.5 2.189 7.188a4.063 4.063 0 0 1 7.813-1.566h0a4.063 4.063 0 0 1 7.812 1.566c0 5.312-7.812 9.687-7.812 9.687Z"
    />
  </svg>
)
export default HeartIcon
