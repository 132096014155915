import * as React from "react";

const IconCoffee = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke={props?.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M7.375 2.25v3M9.875 2.25v3M12.375 2.25v3M3 20.25h13.75M7.008 20.25c-1.197-.659-2.21-1.715-2.92-3.045C3.378 15.875 3 14.329 3 12.75v-4.5h13.75v4.5c0 1.58-.377 3.126-1.088 4.455-.71 1.33-1.724 2.387-2.92 3.045"
    />
    <path
      stroke={props?.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M16.75 8.25c.663 0 1.299.316 1.767.879.47.562.733 1.325.733 2.121V12c0 .796-.264 1.559-.733 2.121-.468.563-1.104.879-1.767.879h-.264"
    />
  </svg>
);
export default IconCoffee;
