import TableSection from "../../components/order/TableSection";
import { Gap } from "../../components/gap/Gap";
import TimeEstimationSection from "../../components/order/TimeEstimationSection";
import MyOrderSection from "../../components/order/MyOrderSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import PageHeader from "../../components/PageHeader";
import { useLanguage } from "../../hooks/useLanguage";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useNavigate } from "react-router-dom";
import { StoreInfo } from "./StoreInfo";
import { OrderInfo } from "./OrderInfo";
import { useAppSelector } from "../../store/hook";

interface BillDetailPageProps {

}

export default function BillDetailPage(props: BillDetailPageProps) {
  const { t } = useLanguage();
  const currentOrder = useAppSelector(root => root.order.currentOrder);
  console.log("=>(BillDetailPage.tsx:21) currentOrder", currentOrder);
  const navigate = useNavigate();
  return <div className={`flex bg-default-1`}>
    <PageHeader title={t("billTitle")} />
    <div className="w-screen h-auto p-4">
      <Gap size={"M1"} />
      <p className={`text-18px font-bold text-brand-4`}>{t("paymentBill")}</p>
      <p className={`text-14px font-normal text-gray-menu`}>{t("number")}</p>
      <p className={`text-14px font-normal text-gray-menu`}>{t("date")}</p>
      <Gap size={"M"} />
      <StoreInfo inTime={Date.now()} outTime={Date.now()} cashier={"Tên thu ngân"} clientType={"Khách lẻ"} />
      <OrderInfo items={currentOrder?.items} />
    </div>
  </div>;
}
