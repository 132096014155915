import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "../models/Product";

interface BottomSheetState {
  open: boolean;
  product?: Product;
}

const initialState: BottomSheetState = {
  open: false,
  product: undefined
};

const bottomSheetSlice = createSlice({
  name: "bottomSheet",
  initialState,
  reducers: {
    openBottomSheet: (state) => {
      state.open = true;
    },
    openProductDetail: (state, action: PayloadAction<Product>) => {
      state.open = true;
      state.product = action.payload;
    },
    closeBottomSheet: (state) => {
      state.open = false;
    }
  }
});

export const { openBottomSheet, openProductDetail, closeBottomSheet } = bottomSheetSlice.actions;
export default bottomSheetSlice.reducer;
