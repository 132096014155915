import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Bill } from "./type";

type BillState = {
  bill: Bill | null;
  loading: boolean;
  error: any;
}

const initialState: BillState = {
  bill: null,
  loading: false,
  error: null
};

export const billSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {
    createBill: (state, action: PayloadAction<Bill>) => {
      state.bill = action.payload;
    },
    updateBill: (state, action) => {
      state.bill = action.payload;
    },
    removeBill: (state, action) => {
      state.bill = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

// Export các action để sử dụng trong các component
export const { createBill, updateBill, removeBill, setLoading, setError } = billSlice.actions;

// Export reducer để sử dụng trong store
export default billSlice.reducer;
