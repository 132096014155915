const development: boolean = process.env.NODE_ENV === "development";

export default function isDev(): boolean {
  return development;
}

export const scrollContent = (scrollRef: any, divId: string) => {
  const { current } = scrollRef;
  const osInstance = current?.osInstance();
  if (!osInstance) {
    return;
  }
  const { scrollOffsetElement } = osInstance.elements();
  const element = document.getElementById(divId);
  const position = element ? element.getBoundingClientRect().top + window.scrollY : 0;
  scrollOffsetElement.scrollTo({
    behavior: "smooth",
    top: position
  });
};
