import { useCallback, useState } from "react";
import {
  clearPasswordlessLoginAttemptInfo,
  createPasswordlessCode,
  getPasswordlessLoginAttemptInfo,
  getThirdPartyAuthorisationURLWithQueryParamsAndSetState,
  resendPasswordlessCode,
} from "supertokens-web-js/recipe/thirdpartypasswordless";

const useAuthentication = () => {
  const [email, setEmail] = useState<string>("");
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  const sendMagicLink = useCallback(async (emailInput: string) => {
    try {
      const response = await createPasswordlessCode({
        email: emailInput,
      });

      if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in / up was not allowed.
        window.alert(response.reason);
      } else {
        setIsOpenPopup(true);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }, []);

  const resendMagicLink = async () => {
    try {
      let response = await resendPasswordlessCode();

      if (response.status === "RESTART_FLOW_ERROR") {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearPasswordlessLoginAttemptInfo();
        window.alert("Login failed. Please try again");
        window.location.assign("/login");
      } else {
        // Magic link resent successfully.
        window.alert("Please check your email for the magic link");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  };

  const hasInitialMagicLinkBeenSent = async () => {
    return (await getPasswordlessLoginAttemptInfo()) !== undefined;
  };

  const isThisSameBrowserAndDevice = async () => {
    return (await getPasswordlessLoginAttemptInfo()) !== undefined;
  };

  async function googleSignInClicked() {
    try {
      const authUrl =
        await getThirdPartyAuthorisationURLWithQueryParamsAndSetState({
          thirdPartyId: "google",
          frontendRedirectURI: `http://localhost:3000/login/auth/callback/google`,
        });

      window.location.assign(authUrl);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }

  return {
    email,
    setEmail,
    sendMagicLink,
    isOpenPopup,
    setIsOpenPopup,
    resendMagicLink,
    hasInitialMagicLinkBeenSent,
    isThisSameBrowserAndDevice,
    googleSignInClicked,
  };
};

export default useAuthentication;
