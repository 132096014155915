import { CategoryProduct, Product } from "../models/Product";

export const indexAllItems = (categories: CategoryProduct[]) => {
  return categories.reduce<Record<string, Product>>((acc, category) => {
    category.items.forEach((item) => {
      acc[item.id] = item;
    });
    return acc;
  }, {});
};
