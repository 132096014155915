import { CartProvider } from "contexts/cart/cart.provider";
import { DrawerProvider } from "contexts/drawer/drawer.provider";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Layout from "../../containers/layout/layout";
import { CategoryProduct, DEFAULT_CATEGORY } from "../../models/Product";
import { useGetMenuQuery } from "../../store/api/menuApi";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_COUNTRY, DEFAULT_LOCATION, DEFAULT_MENU, setSession } from "../../store/sessionSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { v4 as uuidv4 } from "uuid";
import { setAllItems, setCategories, setToppings } from "../../store/itemSlice";
import { indexAllItems } from "../../utils/StoreUtils";
import LoginToUnlockSection from "../../components/home-components/LoginToUnlockSection";
import { Gap } from "../../components/gap/Gap";
import BannerSection from "../../components/home-components/BannerSection";
import { MenuWithCategory } from "../../components/menu/MenuWithCategory";
import { MenuWithCategorySkeleton } from "../../components/menu/MenuWithCategorySkeleton";
import ProductDetailBottomSheet from "../../components/bottom-sheet/ProductDetailBottomSheet";



const HomePage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const storedCategories = useAppSelector(root => root.allItems.categories);
  const [activeCategories, setActiveCategories] = useState(storedCategories);

  // useEffect(() => {
  //   const country = searchParams.get("c") || DEFAULT_COUNTRY;
  //   const location = searchParams.get("l") || DEFAULT_LOCATION;
  //   const table = searchParams.get("t") || undefined;
  //   dispatch(setSession({
  //     session_id: uuidv4(),
  //     country,
  //     location,
  //     table
  //   }));
  // }, []);

  const [allToppings, setAllToppings] = useState<CategoryProduct>();

  const [selectedCategory, setSelectedCategory] =
    useState<CategoryProduct>(DEFAULT_CATEGORY);

  const { data: categories = [], error, isLoading, isSuccess } = useGetMenuQuery({
    country: DEFAULT_COUNTRY,
    location: DEFAULT_LOCATION,
    version: DEFAULT_MENU
  });


  useEffect(() => {
    if (isSuccess && categories) {
      dispatch(setCategories(categories));
      dispatch(setAllItems(indexAllItems(categories as CategoryProduct[])));
      dispatch(setToppings(categories[categories.length - 1]));
      // update active categories to re-render
      setActiveCategories(categories as CategoryProduct[]);
    }
  }, [isSuccess, categories, dispatch]);

  // Remove last categories (toppings)
  const selectableProductsCategories = activeCategories?.slice(0, -1) || [];

  useEffect(() => {
    const TOPPING_INDEX = categories?.length - 1;
    const toppings = categories?.[TOPPING_INDEX];
    setAllToppings(toppings);
    setSelectedCategory(categories[0]);
  }, [categories]);

  return (
    <DrawerProvider allToppings={allToppings}>
      <CartProvider>
        <Layout>
          <Gap size={"XXL"} />
          <div className="px-4">
            <LoginToUnlockSection />
          </div>
          <Gap size={"M"} />
          <BannerSection />
          <div className={`w-full gap-6 flex flex-col`}>
            {isLoading
              ? Array(3).fill(null).map((item, index) => (<MenuWithCategorySkeleton key={`${index}-menu-item`} />))
              : selectableProductsCategories.map((category, index) =>
                <MenuWithCategory
                  key={`${index}-${category.name}`}
                  categoryName={category.name}
                  products={category.items}
                />
              )}
          </div>
          <Gap size={"L"} />
          <ProductDetailBottomSheet />
        </Layout>
      </CartProvider>
    </DrawerProvider>
  );
};

export default HomePage;
