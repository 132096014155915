import { Order } from "../store/type";

const DEFAULT_MENU_ID = 'vn.han_1nx.v1'
const DEFAULT_ZONE = 'han';
export const createOrderDefault = ({
                                     session_id,
                                     user_id = null,
                                     user_name = '',
                                     menu_id = DEFAULT_MENU_ID
                                   }) => {
  return {
    zone: DEFAULT_ZONE,
    menu_id,
    session_id,
    type: "in_store",
    user_id,
    user_name,
    note: "",
    items: [],
    discount_codes: [],
    experience_id: null,
    dedup_id: ""
  } as Order;
};


// QR code => vn, han, street, table => menu


