import React from "react";
import PageHeader from "../../components/PageHeader";
import TableSection from "../../components/order/TableSection";
import TimeEstimationSection from "../../components/order/TimeEstimationSection";
import { Gap } from "../../components/gap/Gap";
import MyOrderSection from "../../components/order/MyOrderSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import { useNavigate } from "react-router-dom";
import { useCreateOrderMutation } from "../../store/api/consumerApi";
import { Bill, Order } from "../../store/type";
import { createBill } from "../../store/billSlice";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { v4 as uuidv4 } from "uuid";
import { useLanguage } from "../../hooks/useLanguage";
import Button from "../../components/button";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";

const OrdersPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentOrder = useAppSelector(root => root.order.currentOrder);
  console.log("=>(OrderPage.tsx:22) currentOrder", currentOrder);
  const { language, t } = useLanguage();

  const totalPrice = cartItemsTotalPrice(currentOrder?.items || []);

  const [createOrder, { isLoading: isCreatingOrder }] = useCreateOrderMutation();
  const showCheckout = async () => {
    const orderWithDedupeId = { ...currentOrder, dedup_id: uuidv4() };
    const createOrderResponse = await createOrder({ order: orderWithDedupeId as Order, locale: language });
    const bill = createOrderResponse.data;
    dispatch(createBill(bill as Bill));
    navigate("/checkout");
  };

  return <div className="bg-default-1 w-screen h-full p-4">
    <Gap size={"M1"} />
    <TableSection />
    <Gap size={"S"} />
    <TimeEstimationSection />
    <Gap size={"S"} />
    <MyOrderSection items={currentOrder?.items} />
    <Gap size={"S"} />
    <TotalPriceSection total={totalPrice} subTotal={totalPrice} />
    <Gap size={"M"} />
    <Button loading={isCreatingOrder} className={`bg-brand-1 w-full py-2 rounded-[40px]`} onClick={showCheckout}>
      <p className={`text-white text-14px font-semibold`}>{t("checkout")}</p>
    </Button>
    <Gap size={"XL"} />
  </div>;
};

export default OrdersPage;
