import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import ThirdPartyPasswordless from "supertokens-web-js/recipe/thirdpartypasswordless";
import "./i18n.ts";

SuperTokens.init({
  appInfo: {
    apiDomain: "https://consumers-api.dev.palettek.io",
    apiBasePath: "/api/auth",
    appName: "san-consumer-app",
  },
  recipeList: [Session.init(), ThirdPartyPasswordless.init()],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
