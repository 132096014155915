import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const menuApi = createApi({
  reducerPath: "menuApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://menus-api.dev.palettek.io/v1"
  }),
  endpoints: (builder) => ({
    getMenu: builder.query({
      query: (requestParams) => ({
        url: "/get_menu",
        method: "POST",
        body: {
          jsonrpc: "2.0",
          id: "0",
          method: "get_menu",
          params: requestParams
        }
      }),
      transformResponse: (baseQueryReturnValue: any, meta, arg) => baseQueryReturnValue?.result?.categories
    }),
    getMenuItem: builder.query({
      query: (requestParams) => ({
        url: "/get_menu_item",
        method: "POST",
        body: {
          jsonrpc: "2.0",
          id: "0",
          method: "get_menu_item",
          params: requestParams
        }
      }),
      transformResponse: (baseQueryReturnValue: any, meta, arg) => baseQueryReturnValue?.result
    })
  })
});

export const { useGetMenuQuery, useLazyGetMenuQuery, useGetMenuItemQuery, useLazyGetMenuItemQuery } = menuApi;

