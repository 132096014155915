import { useSearchParams } from "react-router-dom";
import { VNPayCallbackParams } from "../helpers/constants";
import { UpdatePaymentRequestParams } from "../store/type";

const vnpayCallbackParamsArray: string[] = Object.values(VNPayCallbackParams);

const SHOULD_PARSE_TO_NUMBER = [
  VNPayCallbackParams.vnp_Amount,
  VNPayCallbackParams.vnp_PayDate,
  VNPayCallbackParams.vnp_TransactionNo
];

const useVnpayCallbackParams = () => {
  const [searchParams] = useSearchParams();

  const vnpayParams = vnpayCallbackParamsArray.reduce((acc, param: string) => {
    const value = searchParams.get(param) || "" as string | number;
    acc[param] = SHOULD_PARSE_TO_NUMBER.includes(param as VNPayCallbackParams) ? (Number(value) || 0) : value;
    return acc;
  }, {} as UpdatePaymentRequestParams);

  return vnpayParams;
};

export default useVnpayCallbackParams;
