import { Product } from "../../models/Product";
import ChevronRight from "../../assets/icons/chevron-right";
import MenuProductItem from "./MenuProductItem";

type MenuWithCategoryProps = {
  categoryName: string;
  products: Product[];
}

export function MenuWithCategory(props: MenuWithCategoryProps) {
  const { categoryName, products } = props;
  return <div className="w-full">
    <div className={`w-full`}>
      <span className={`text-brand-4 font-bold text-16px ml-4`}>{categoryName}</span>
      {
        products.length > 5 &&
        <div>
          <span>{`Xem thêm ${products.length - 5}`}</span>
          <ChevronRight />
        </div>
      }
    </div>
    <div className="flex flex-row overflow-scroll gap-2 scrollbar-hide pr-4">
      {products.map((product, index) =>
        <MenuProductItem key={`${index}-product-item`} product={product} />)}
    </div>
  </div>;
}
