import { calculateItemTotalPrice, formatCurrency } from "../../utils/ConcurencyUtils";
import { useAppSelector } from "../../store/hook";
import { Order } from "../../store/type";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";
import ChevronRight from "../../assets/icons/chevron-right";
import { useLanguage } from "../../hooks/useLanguage";
import { closeBottomSheet } from "../../store/bottomSheetSlice";
import { useState } from "react";

import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { s3ImageUrl } from "../../utils/FileUtils";
import { Gap } from "../gap/Gap";

export interface PaymentMethodSectionProps {
  className?: string;
}

const Divide = () => (<div className={`w-full h-[1px] bg-divide`} />);


export default function PaymentMethodSection(props: PaymentMethodSectionProps) {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);

  const paymentMethods = [
    {
      id: 1,
      name: "VNPay",
      logo: "san-logo.png"
    },
    {
      id: 2,
      name: t("cash"),
      logo: "san-logo.png"
    }
  ];

  return <div className={`flex flex-col bg-white p-4 rounded-lg ${props.className || ""}`}>
    <div>
      <p className={`text-16px font-bold text-brand-4`}>{t("paymentMethod")}</p>
    </div>
    <div className="flex flex-row justify-between py-2" onClick={() => setOpen(!open)}>
      <div className={`flex flex-row justify-start items-center py-2`}>
        <img className={`w-8 h-8 rounded-full`} src={s3ImageUrl(paymentMethods?.[selectedPaymentIndex].logo)} alt="" />
        <p className={`text-14px font-normal text-gray-menu ml-2`}>{paymentMethods?.[selectedPaymentIndex].name}</p>
      </div>
      <div className={"flex flex-row justify-between items-center"}>
        {/*<p className={`mr-3`}>-20%</p>*/}
        <ChevronRight color="#9F2B2B" width={"10px"} height={"12px"} />
      </div>
    </div>
    <BottomSheet
      open={open}
      expandOnContentDrag
      onDismiss={() => {
        setOpen(false);
      }}
    >
      <div className={`h-fit w-full b g-red-200 flex flex-col px-2`}>
        <p className={`text-16px font-bold text-brand-4 text-center`}>{t("paymentMethod")}</p>
        <Gap size={"M"} />
        {paymentMethods.map((item, index) =>
          (<div key={index}
                className={`flex flex-row justify-start items-center py-2 px-4
                ${index === selectedPaymentIndex ? "border-primary border-[1px] rounded-[16px]" : ""}`}
                onClick={() => {
                  setSelectedPaymentIndex(index);
                }}>
            <img className={`w-8 h-8 rounded-full`} src={s3ImageUrl(item.logo)} alt="" />
            <p className={`text-14px font-normal text-gray-menu ml-2`}>{item.name}</p>
          </div>))}
        <Gap size={"M"} />
        <div className={`w-full pb-4 px-2`}>
          <button className={`px-8 py-[6px] w-full rounded-[40px] bg-brand-1`} onClick={() => {
            setOpen(false);
          }}>
            <p className={`text-14px font-semibold text-white`}>{t("agree")}</p>
          </button>
        </div>
      </div>
    </BottomSheet>
  </div>;
}
