import * as React from "react"
const IconClock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#9F2B2B"
      strokeMiterlimit={10}
      d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
    />
    <path
      stroke="#9F2B2B"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 4.5V8h3.5"
    />
  </svg>
)
export default IconClock
