import * as React from "react";

const IconCart = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke={props?.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M18.5 17.25H6.921a.75.75 0 0 1-.738-.616L3.68 2.866a.75.75 0 0 0-.738-.616H1.25"
      />
      <path
        stroke={props?.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M7.25 21a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM18.5 21a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM4.25 6h16.351a.75.75 0 0 1 .738.884l-1.227 6.75a.75.75 0 0 1-.738.616H5.75"
      />
    </svg>
  );
};
export default IconCart;
