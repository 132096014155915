import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryProduct, Product } from "../models/Product";

interface ItemState {
  allItems: Record<string, Product>;
  categories: CategoryProduct[];
  selectedItem: Product | null;
  toppings: CategoryProduct | null;
}

const initialState: ItemState = {
  allItems: {},
  categories: [],
  selectedItem: null,
  toppings: null
};

const itemSlice = createSlice({
  name: "allItems",
  initialState,
  reducers: {
    setAllItems: (state, action: PayloadAction<Record<string, Product>>) => {
      state.allItems = action.payload;
    },
    setCategories: (state, action: PayloadAction<CategoryProduct[]>) => {
      state.categories = action.payload;
    },
    setToppings: (state, action: PayloadAction<CategoryProduct>) => {
      state.toppings = action.payload;
    }
  }
});

export const {
  setAllItems,
  setCategories,
  setToppings
} = itemSlice.actions;
export default itemSlice.reducer;
