import TableSection from "../../components/order/TableSection";
import { Gap } from "../../components/gap/Gap";
import TimeEstimationSection from "../../components/order/TimeEstimationSection";
import MyOrderSection from "../../components/order/MyOrderSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import PageHeader from "../../components/PageHeader";
import { useLanguage } from "../../hooks/useLanguage";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useNavigate } from "react-router-dom";

interface OrderDetailPageProps {

}

export default function OrderDetailPage(props: OrderDetailPageProps) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  return <div className={`flex bg-default-1`}>
    <PageHeader title={t("orderDetailTitle")} />
    <div className="w-screen h-auto p-4">
      <Gap size={"M1"} />
      <div className={`flex flex-col justify-center items-center`}>
        <p className={`text-18px font-bold text-brand-4`}>{t("orderInProgress")}</p>
        <Gap size={"M"} />
        <img src={s3ImageUrl("order_in_progress.png")} className={`w-3/4`} alt="" />
      </div>
      <Gap size={"M1"} />
      <TableSection />
      <Gap size={"S"} />
      <TimeEstimationSection />
      <Gap size={"S"} />
      <MyOrderSection items={[]} />
      <Gap size={"S"} />
      <TotalPriceSection total={0} subTotal={0} discount={0} />
      <Gap size={"S"} />
      <button
        className={`border-solid py-3 w-full bg-brand-1 mt-4 border-2 rounded-[40px]`}
        onClick={() => {
        }}
      >
        <p className={`font-semibold text-14px text-white`}>{t("reviewProduct")}</p>
      </button>
      <button
        className={`border-solid py-3 w-full bg-white mt-2 border-2 rounded-[40px]`}
        onClick={() => {
          navigate("/bill-detail");
        }}
      >
        <p
          className={`font-semibold text-14px text-brand-4`}>{t("checkBill")}</p>
      </button>
    </div>
  </div>;
}
