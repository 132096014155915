import LessIce from "../../../assets/icons/ice/less-ice";
import NormalIce from "../../../assets/icons/ice/normal-ice";
import MoreIce from "../../../assets/icons/ice/more-ice";

const IceSelector = ({ value, checked, handleChange, label }) => {

  const sizeComponentMapping = {
    25: LessIce,
    50: NormalIce,
    75: MoreIce
  };

  const SizeComponent = sizeComponentMapping?.[value] || null;

  return (
    <div
      key={value}
      onClick={() => {
        handleChange(value);
      }}
      className={`relative mr-2 w-fit rounded-full flex flex-col items-center justify-end px-3 py-3px`}
    >
      {SizeComponent && <SizeComponent color={checked ? "#9F2B2B" : "#131313"} />}
      <span className={`text-[14px] mt-2 ${checked ? `text-[#9F2B2B] underline` : "text-black"}`}>
        {label}
      </span>
    </div>
  );
};

export default IceSelector;
