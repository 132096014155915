import { useLanguage } from "../../hooks/useLanguage";

export interface TableSectionProps {
  className?: string;
}

export default function TableSection(props: TableSectionProps) {
  const { t } = useLanguage();
  return <div className={`flex flex-row bg-white p-4 rounded-lg justify-between items-center ${props.className || ""}`}>
    <div>
      <p className={`text-16px font-bold text-brand-4`}>{`${t("table")} 02`}</p>
      <p className={`text-12px font-normal text-tertiary`}>{t('orderOnTable')}</p>
    </div>
    <div>
      <button className={`bg-brand-1 px-8 py-[9px] rounded-[40px]`}>
        <p className={`text-14px font-semibold text-white`}>{t('changeTable')}</p>
      </button>
    </div>
  </div>;
}
