import { s3ImageUrl } from "../../utils/FileUtils";

export default function LoginToUnlockSection() {
  return <div className="relative bg-secondary-4 flex row rounded-[16px] p-4 justify-between pr-24">
    <div className="flex flex-col justify-center items-start">
      <p className={`text-brand-4 font-bold text-14px`}>Nhận ưu đãi độc quyền</p>
      <p className={`text-brand-4 font-normal leading-[16px] text-12px mt-2`}>{`
      Đăng ký ngay để mở khóa hàng loạt tính năng 
      độc quyền và nhận ưu đãi hấp dẫn chỉ dành cho tài khoản mới!`}</p>
      <button className={`mt-4 bg-white text-brand-4 py-2 px-4 rounded-[40px]`}>
        Đăng ký/ Đăng nhập
      </button>
    </div>
    <div className={`absolute right-0 top-0 bottom-0`}>
      <img src={s3ImageUrl(`san_logo_mark.png`)} className="h-full" alt="" />
    </div>
  </div>;
}
