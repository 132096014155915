import TableSection from "../../components/order/TableSection";
import { Gap } from "../../components/gap/Gap";
import TimeEstimationSection from "../../components/order/TimeEstimationSection";
import MyOrderSection from "../../components/order/MyOrderSection";
import TotalPriceSection from "../../components/order/TotalPriceSection";
import PageHeader from "../../components/PageHeader";
import { useAppSelector } from "../../store/hook";
import { cartItemsTotalPrice } from "../../contexts/cart/cart.reducer";
import { formatCurrency } from "../../utils/ConcurencyUtils";
import PaymentMethodSection from "../../components/order/PaymentMethodSection";
import { useLanguage } from "../../hooks/useLanguage";
import { Link } from "react-router-dom";

interface CheckoutPageProps {

}

export default function CheckoutPage(props: CheckoutPageProps) {
  const { t } = useLanguage();
  const lastBill = useAppSelector(root => root.bill.bill);
  const { items, vnp_url = "", charges } = lastBill || {};
  const {total = 0, sub_total, discount} = charges || {};
  console.log("=>(CheckoutPage.tsx:22) lastBill", lastBill);
  return <div className={`relative h-screen`}>
    <PageHeader title={t("payment")} />
    <div className="bg-default-1 w-screen h-screen p-4">
      <Gap size={"M1"} />
      <TableSection />
      <Gap size={"S"} />
      <TimeEstimationSection />
      <Gap size={"S"} />
      <MyOrderSection items={items} />
      <Gap size={"S"} />
      <TotalPriceSection total={total} subTotal={sub_total} discount={discount} />
      <Gap size={"S"} />
      <PaymentMethodSection />
      <Gap size={"XL"} />
    </div>
    <div
      className={`bottom-0 fixed left-0 right-0 w-full flex flex-row justify-between h-[87px] bg-brand-1 p-4 rounded-t-[16px]`}>
      <div>
        <p className={`text-12px font-normal text-white`}>{t("totalPayment")}</p>
        <p className={`text-16px font-bold text-white`}>{formatCurrency(total)}</p>
      </div>
      <Link to={vnp_url} rel="noopener noreferrer">
        <button className={`px-8 py-[6px] rounded-[40px] bg-white`}>
          <p className={`text-14px font-semibold text-brand-1`}>{t("payment")}</p>
        </button>
      </Link>
    </div>
  </div>;
}
