import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import BottomTabBar from "./components/BottomTabbar";
import PageHeader from "./components/PageHeader";
import IconHome from "./assets/icons/icon-home";
import IconCoffee from "./assets/icons/icon-coffee";
import IconCart from "./assets/icons/icon-cart";
import IconProfile from "./assets/icons/icon-profile";

const MainLayout: React.FC = () => {
  const menus = [
    {
      id: 1,
      href: "/",
      title: "Home",
      showHeader: false,
      icon: IconHome
    }, {
      id: 2,
      href: "/menu",
      title: "Menu",
      showHeader: false,
      icon: IconCoffee
    },
    {
      id: 3,
      href: "/orders",
      title: "Orders",
      showHeader: true,
      icon: IconCart
    },
    {
      id: 4,
      href: "/profile",
      title: "Profile",
      showHeader: true,
      icon: IconProfile
    }
  ];

  const { pathname } = useLocation();

  const activeMenu = menus.find(menu => menu.href === pathname);

  return (
    <div className="flex flex-col min-h-screen bg-default-1 w-screen max-w-screen-md">
      {activeMenu?.showHeader ? <PageHeader title={activeMenu?.title || ""} /> : null}
      <div className="flex-grow">
        <Outlet />
      </div>
      <BottomTabBar />
    </div>
  );
};

export default MainLayout;
