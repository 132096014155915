import React, { useState } from "react";
import RadioSelector from "../selector/RadioSelector";
import type { RadioChangeEvent } from "antd";
import IceSelector from "../../containers/drawer/views/IceSelector";

export interface VariantSelectionProps {
  icePercent: number;
  setIcePercent: (value: number) => void;
  isHot: boolean;
  setIsHot: (value: boolean) => void;
  scrollToIceSelector?: () => void;
}

export default function VariantSelection(props: VariantSelectionProps) {
  const { icePercent, setIcePercent, isHot, setIsHot, scrollToIceSelector = () => null } = props;
  const radioItems = [
    {
      value: true,
      label: "Nóng/ Ấm"
    },
    {
      value: false,
      label: "Lạnh/ Đá"
    }
  ];

  const [radioValue, setRadioValue] = useState(isHot);

  const onChangeRadio = (e: RadioChangeEvent) => {
    const hot = e.target.value;
    setRadioValue(hot);
    !hot && scrollToIceSelector?.();
    setIsHot(hot);
  };

  return (
    <div>
      <div className={`border-solid border-[1px] border-[#E0E0E0] rounded-[16px] p-3`}>
        <RadioSelector
          items={radioItems}
          value={radioValue}
          onChange={onChangeRadio}
        />
      </div>
      <div className={`${!isHot ? "opacity-100" : "opacity-0"} transition-opacity ease-in-out delay-150 duration-300`}>
        <p className={`my-2 mt-6 text-[14px] font-bold`}>Lượng đá</p>
        <div className={`w-full flex flex-row justify-between px-10`}>
          {[25, 50, 75]?.map((size) => {
            return (
              <IceSelector
                key={size}
                label={`${size}%`}
                handleChange={() => {
                  setIcePercent(size);
                }}
                value={size}
                checked={icePercent === size}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
