import IconClock from "../../assets/icons/icon-clock";
import { Gap } from "../gap/Gap";
import { useLanguage } from "../../hooks/useLanguage";

export interface TimeEstimationSectionProps {
  className?: string;
}

export default function TimeEstimationSection(props: TimeEstimationSectionProps) {
  const { t } = useLanguage();
  return <div className={`flex flex-row bg-white p-2 rounded-lg justify-start items-center ${props.className || ""}`}>
    <IconClock />
    <p className={`text-12px font-normal text-gray-menu ml-1 mt-0.5`}>{t("estimation")}</p>
    <p className={`text-14px font-normal text-brand-1 ml-1`}>{`26 ${t("minute")}`}</p>
  </div>;
}
