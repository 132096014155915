import SmallSize from "../../../assets/icons/size/small-size";
import MediumSize from "../../../assets/icons/size/medium-size";
import LargeSize from "../../../assets/icons/size/large-size";
import { s3ImageUrl } from "../../../utils/FileUtils";

const SizeItem = ({ value, checked, handleChange, label }) => {

  const sizeComponentMapping = {
    small: SmallSize,
    medium: MediumSize,
    large: LargeSize
  };

  const bottomMapping = {
    small: 44,
    medium: 48,
    large: 55
  };

  const labelMapping = {
    small: 'Nhỏ',
    medium: 'Vừa',
    large: 'Lớn'
  };

  const SizeComponent = sizeComponentMapping?.[value] || null;

  return (
    <div
      key={value}
      onClick={() => {
        handleChange(value);
      }}
      className={`relative mr-2 w-fit rounded-full flex flex-col items-center justify-end px-3 py-3px`}
    >
      {SizeComponent && <SizeComponent color={checked ? "#9F2B2B" : "#131313"} />}
      {checked && <img style={{
        bottom: bottomMapping?.[value] || 0
      }} src={s3ImageUrl("size_checked.png")} className={`absolute left-[24.5px] w-4 h-4`} alt="" />}
      <span className={`text-[14px] mt-2 ${checked ? `text-[#9F2B2B] underline` : "text-black"}`}>
        {labelMapping?.[value]}
      </span>
    </div>
  );
};

export default SizeItem;
