import { ProductItem } from "../../store/type";
import IconMinus from "../../assets/icons/icon-minus";
import IconPlus from "../../assets/icons/icon-plus";
import { formatCurrency } from "../../utils/ConcurencyUtils";
import { useState } from "react";
import { removeItem as removeItemById, updateItemQuantity } from "../../store/orderSlice";
import { useAppDispatch } from "../../store/hook";
import { Button, Modal } from 'antd';

export interface OrderItemProps {
  item: ProductItem;
  showDivide: boolean;
}

export default function OrderItem(props: OrderItemProps) {
  const { item, showDivide } = props || {};
  const { name, image, size, children, price, quantity, id } = item || {};
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      removeItem();
    }, 1000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const addItem = () => dispatch(updateItemQuantity({ id, quantity: quantity + 1 }));

  const removeItem = () => {
    if (quantity === 1) dispatch(removeItemById(id));
    else dispatch(updateItemQuantity({ id, quantity: quantity - 1 }));
  };

  const onRemoveItem = () => {
    if(quantity === 1) showModal();
    else removeItem();
  }

  return <div>
    <div className={`flex flex-row justify-between items-center mt-2`}>
      <div className={`flex flex-row items-center`}>
        <img src={image} alt="" className={`w-14 h-14 rounded-lg`} />
        <div className={`ml-2`}>
          <p className={`text-14px font-normal text-brand-4 line-clamp-1`}>{name}</p>
          <p className={`text-12px font-normal text-tertiary`}>{size}</p>
          <p className={`text-12px font-normal text-brand-1`}>{formatCurrency(price)}</p>
        </div>
      </div>
      <div className={`flex flex-row items-center border border-border-1 rounded-[40px] px-2`}>
        <IconMinus color={"#9F2B2B"} onClick={onRemoveItem} />
        {/* B5B5B5 */}
        <input
          type="number"
          value={quantity}
          className={`w-[24px] text-center text-brand-4`}
          onChange={(event) => {
            // setQuantityValue(+event.target.value);
          }}
        />
        <IconPlus color={"#9F2B2B"} onClick={addItem} />
      </div>
    </div>
    {showDivide && <div className={`w-full h-[1px] bg-[#FAFAFA] mt-2`} />}
    <Modal
      title="Xác nhận"
      open={open}
      style={{marginTop: '50%'}}
      onOk={handleOk}
      okText={"Xác nhận"}
      okButtonProps={{style: {backgroundColor: "#9F2B2B", fontSize: "12px"}}}
      cancelText={"Huỷ bỏ"}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>{`Bạn có chắc muốn xoá ${name}`}</p>
    </Modal>
  </div>;
}
