import * as React from "react";

const IconGame = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M14.75 10.125h3M7.25 10.125h3M8.75 8.625v3M16.627 5.226l-8.252.024a4.876 4.876 0 0 0-4.801 4.028h0l-1.534 7.89a2.625 2.625 0 0 0 4.442 2.313h0L10.535 15l6.092-.024a4.875 4.875 0 1 0 0-9.75v0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="m21.428 9.254 1.533 7.915a2.625 2.625 0 0 1-4.442 2.312h0l-4.05-4.497"
    />
  </svg>
);
export default IconGame;
