import ChevronLeft from "../assets/icons/chevron-left";
import { useNavigate } from "react-router-dom";

type PageHeaderProps = {
  title: string;
  IconRight?: React.FC<{ className?: string, color?: string }>;
  onIconRightClick?: () => void;
}

export default function PageHeader(props: PageHeaderProps) {
  const navigate = useNavigate();
  const { IconRight, onIconRightClick } = props;
  return (
    <header
      className="flex fixed w-screen bg-white items-center h-[58px] px-4 flex-row justify-between">
      <div onClick={() => {
        navigate(-1);
      }} className="w-9 h-9 flex items-center justify-center cursor-pointer">
        <ChevronLeft color={"#31261D"} />
      </div>
      <span className={`text-brand-4 font-bold text-[16px]`}>{props.title}</span>
      <div className={`w-9 h-9 flex items-center justify-center`}>
        {IconRight && <IconRight />}
      </div>
    </header>
  );
}
