import React from "react";
import { Product } from "../../models/Product";
import { Skeleton } from "antd";
import MenuProductItem from "./MenuProductItem";

type MenuWithCategoryProps = {
  categoryName: string;
  products: Product[];
}

export function MenuWithCategorySkeleton() {
  return <div className="w-full pt-2">
    <div className={`w-full pl-4`}>
      <Skeleton.Input active style={{ height: 16 }} size="small" />
    </div>
    <div className="flex flex-row overflow-scroll gap-2 scrollbar-hide">
      {
        Array(5).fill(null).map((_, i) => <div key={`skeleton-${i}`}>
          <Skeleton.Node active style={{ height: 168, width: 148, marginLeft: 16, marginTop: 8, borderRadius: 16 }} />
          <div className="flex flex-row justify-between ml-4 mt-4">
            <Skeleton.Button active style={{ height: 12, width: 24 }} />
            <Skeleton.Button active style={{ height: 12, width: 4 }} size="small" />
          </div>
          <Skeleton.Button active style={{ height: 34, width: 148, marginLeft: 16, borderRadius: 40}} size="small" />
        </div>)
      }
    </div>
  </div>;
}
