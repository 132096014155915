import * as React from "react";

const IconSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#31261D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M9.063 15.625a6.562 6.562 0 1 0 0-13.125 6.562 6.562 0 0 0 0 13.125ZM13.703 13.703 17.5 17.5"
    />
  </svg>
);
export default IconSearch;
