// CONSTANTS
export const CURRENCY = "đ";
export const productKeys = [
  "id",
  "name",
  "description",
  "image",
  "price",
  "manufacturer",
  "type",
  "quantity",
  "dosage",
  "substance",
  "category_ids",
];

export const categoryKeys = ["id", "name", "slug", "image_icon_url"];

export enum VNPayCallbackParams {
  vnp_Amount = "vnp_Amount",
  vnp_BankCode = "vnp_BankCode",
  vnp_CardType = "vnp_CardType",
  vnp_OrderInfo = "vnp_OrderInfo",
  vnp_PayDate = "vnp_PayDate",
  vnp_ResponseCode = "vnp_ResponseCode",
  vnp_TmnCode = "vnp_TmnCode",
  vnp_TransactionNo = "vnp_TransactionNo",
  vnp_TransactionStatus = "vnp_TransactionStatus",
  vnp_TxnRef = "vnp_TxnRef",
  vnp_SecureHash = "vnp_SecureHash",
  vnp_BankTranNo = "vnp_BankTranNo"
}

export enum VnpayTransactionStatus {
  Success = "00", // Giao dịch thành công
  Incomplete = "01", // Giao dịch chưa hoàn tất
  Error = "02", // Giao dịch bị lỗi
  Reversed = "04", // Giao dịch đảo
  ProcessingRefund = "05", // VNPAY đang xử lý giao dịch này (GD hoàn tiền)
  RefundRequested = "06", // VNPAY đã gửi yêu cầu hoàn tiền sang Ngân hàng
  FraudSuspected = "07", // Giao dịch bị nghi ngờ gian lận
  RefundDeclined = "09", // GD Hoàn trả bị từ chối
}

export const transactionStatusDescriptions: Record<VnpayTransactionStatus, string> = {
  [VnpayTransactionStatus.Success]: "Giao dịch thành công",
  [VnpayTransactionStatus.Incomplete]: "Giao dịch chưa hoàn tất",
  [VnpayTransactionStatus.Error]: "Giao dịch bị lỗi",
  [VnpayTransactionStatus.Reversed]: "Giao dịch đảo",
  [VnpayTransactionStatus.ProcessingRefund]: "VNPAY đang xử lý giao dịch này (GD hoàn tiền)",
  [VnpayTransactionStatus.RefundRequested]: "VNPAY đã gửi yêu cầu hoàn tiền sang Ngân hàng",
  [VnpayTransactionStatus.FraudSuspected]: "Giao dịch bị nghi ngờ gian lận",
  [VnpayTransactionStatus.RefundDeclined]: "GD Hoàn trả bị từ chối",
};

enum VnpayResponseCode {
  Success = "00", // Giao dịch thành công
  FraudSuspected = "07", // Trừ tiền thành công. Giao dịch bị nghi ngờ
  TransactionFailedNotRegistered = "09", // Giao dịch không thành công do: Thẻ/Tài khoản chưa đăng ký dịch vụ Internet Banking
  TransactionFailedIncorrectInfo = "10", // Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần
  TransactionFailedTimeout = "11", // Giao dịch không thành công do: Đã hết hạn chờ thanh toán
  TransactionFailedAccountLocked = "12", // Giao dịch không thành công do: Thẻ/Tài khoản bị khóa
  TransactionFailedIncorrectOTP = "13", // Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP)
  TransactionCanceled = "24", // Giao dịch không thành công do: Khách hàng hủy giao dịch
  InsufficientBalance = "51", // Giao dịch không thành công do: Tài khoản không đủ số dư
  ExceededTransactionLimit = "65", // Giao dịch không thành công do: Tài khoản đã vượt quá hạn mức giao dịch trong ngày
  BankMaintenance = "75", // Ngân hàng thanh toán đang bảo trì
  IncorrectPaymentPassword = "79", // Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định
  OtherErrors = "99", // Các lỗi khác
}

export const responseCodeDescriptions: Record<VnpayTransactionStatus, string> = {
  [VnpayResponseCode.Success]: "Giao dịch thành công",
  [VnpayResponseCode.FraudSuspected]: "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).",
  [VnpayResponseCode.TransactionFailedNotRegistered]: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.",
  // @ts-ignore
  [VnpayResponseCode.TransactionFailedIncorrectInfo]: "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần.",
  [VnpayResponseCode.TransactionFailedTimeout]: "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.",
  [VnpayResponseCode.TransactionFailedAccountLocked]: "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.",
  [VnpayResponseCode.TransactionFailedIncorrectOTP]: "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.",
  [VnpayResponseCode.TransactionCanceled]: "Giao dịch không thành công do: Khách hàng hủy giao dịch.",
  [VnpayResponseCode.InsufficientBalance]: "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.",
  [VnpayResponseCode.ExceededTransactionLimit]: "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.",
  [VnpayResponseCode.BankMaintenance]: "Ngân hàng thanh toán đang bảo trì.",
  [VnpayResponseCode.IncorrectPaymentPassword]: "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch.",
  [VnpayResponseCode.OtherErrors]: "Các lỗi khác (lỗi còn lại, không có trong danh sách mã lỗi đã liệt kê).",
};
