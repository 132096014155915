import * as React from "react"
const LargeSize = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={85}
    fill="none"
    {...props}
  >
    <path
      stroke={props?.color}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M28.328 83.616H13.672a5.704 5.704 0 0 1-5.623-4.732L3.04 24.678H38.96L33.95 78.884a5.712 5.712 0 0 1-5.623 4.732ZM39.242 17.88H2.758c-.97 0-1.758.787-1.758 1.758v3.282c0 .971.787 1.758 1.758 1.758h36.484c.97 0 1.758-.787 1.758-1.758v-3.282c0-.971-.787-1.758-1.758-1.758ZM20.996 1h-1.083C10.598 1 3.04 8.557 3.04 17.88H38.96C38.959 8.557 31.402 1 22.079 1h-1.083Z"
    />
  </svg>
)
export default LargeSize
