import { useLanguage } from "../../hooks/useLanguage";
import dayjs from "dayjs";
import { ProductItem } from "../../store/type";
import { formatCurrency } from "../../utils/ConcurencyUtils";

export type OrderInfoProps = {
  items?: ProductItem[];
}

export function OrderInfo(props: OrderInfoProps) {
  const { t } = useLanguage();
  const {items = []} = props;
  return <div className={`bg-white rounded-lg px-4 py-2`}>
    {items?.map((item, index) => (
      <div key={`${index}-${item.item_id}-store-info`} className={`flex flex-col`}>
        <div className={`flex flex-row w-full justify-between py-2`}>
          <div className={`flex flex-row w-full justify-between`}>
            <p className={`font-normal text-brand-4 text-14px`}>{item.name}</p>
            <p className={`font-bold text-brand-4 text-14px`}>{formatCurrency(item.price)}</p>
          </div>
          <div className={`flex flex-row w-full justify-between mt-1`}>
            <p className={`font-normal text-tertiary text-12px`}>{t("quatity")}</p>
            <p className={`font-normal text-tertiary text-14px`}>{`x${item.quantity}`}</p>
          </div>
          <div className={`flex flex-row w-full justify-between mt-1`}>
            <p className={`font-normal text-tertiary text-12px`}>{t("price")}</p>
            <p className={`font-normal text-tertiary text-14px`}>{formatCurrency(item.price * item.quantity)}</p>
          </div>
        </div>
        {index < items?.length - 1 && (<div className={`w-full h-[1px] bg-divide`} />)}
      </div>))}
  </div>;
}
