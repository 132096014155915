import React, { useCallback, useEffect, useState } from "react";
import CheckMailPopup from "./components/CheckMailPopup";
import useAuthentication from "./hooks/useAuthentication";
import Button from "components/button";
import GoogleLogo from "assets/logos/google";

const SignInPage: React.FC = () => {
  //   const navigate = useNavigate();
  const {
    email,
    setEmail,
    sendMagicLink,
    isOpenPopup,
    setIsOpenPopup,
    resendMagicLink,
    googleSignInClicked,
  } = useAuthentication();

  const handleButtonClick = useCallback(() => {
    // console.log("🇻🇳 ~ email:", email);
    sendMagicLink(email);
  }, [email]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  return (
    <div className="flex h-screen w-full items-center justify-center bg-white">
      <div className="h-max max-w-480px rounded bg-white px-10 py-8  drop-shadow-lg">
        <div>
          <h1 className="text-3xl mb-2 font-bold">Welcome to Boba</h1>
          {/* <h1 className="text-xl mt-8 font-bold">Social Sign in</h1> */}

          <button
            className="mb-3 flex w-full flex-row items-center justify-center rounded-md border bg-white py-2 text-white"
            onClick={googleSignInClicked}
          >
            <GoogleLogo />
            <div className="ml-2 text-black">Continue with Google</div>
          </button>

          <div className="h-px bg-[#dddddd]" />
          <h1 className=" mb-2 mt-8 text-16px">Email</h1>
          <input
            className="mb-3.5 w-full rounded-md border px-2.5 py-2"
            type="text"
            value={email}
            onChange={handleChange}
            required
            placeholder="Enter your email"
          />

          <Button className="big w-full" onClick={handleButtonClick}>
            SIGN UP OR SIGN IN
          </Button>
        </div>
      </div>
      <CheckMailPopup
        isOpen={isOpenPopup}
        onClose={() => setIsOpenPopup(false)}
        resendMagicLink={resendMagicLink}
      />
    </div>
  );
};

export default SignInPage;
