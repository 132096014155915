import { useLanguage } from "../../hooks/useLanguage";
import dayjs from "dayjs";

export type StoreInfoProps = {
  inTime: number;
  outTime: number;
  cashier: string;
  clientType: string;
}

export function StoreInfo(props: StoreInfoProps) {
  const { t } = useLanguage();
  const infoList = [
    {
      title: t("inTime"),
      value: props.inTime,
      type: "date"
    },
    {
      title: t("outTime"),
      value: props.outTime,
      type: "date"
    },
    {
      title: t("cashier"),
      value: props.cashier
    },
    {
      title: t("clientType"),
      value: props.clientType
    }
  ];

  return <div className={`bg-white rounded-lg px-4 py-2`}>
    {infoList.map((item, index) => (
      <div key={`${index}-${item.value}-store-info`} className={`flex flex-col`}>
        <div className={`flex flex-row w-full justify-between py-2`}>
          <p className={`text-14px font-normal text-gray-menu`}>{item.title}</p>
          <p
            className={`text-14px font-normal text-brand-4`}>{item?.type === "date" ? dayjs(item.value).format("HH:mm DD/MM/YYYY")
            : item.value}</p>
        </div>
        {index < infoList.length - 1 && (<div className={`w-full h-[1px] bg-divide`} />)}
      </div>))}
  </div>;
}
