import React, { useCallback, useEffect, useState, useRef } from "react";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { closeBottomSheet } from "../../store/bottomSheetSlice";
import Products from "../../containers/products";
import { Product } from "../../models/Product";
import { useLanguage } from "../../hooks/useLanguage";
import VariantSelection from "./VariantSelection";

export interface ProductDetailBottomSheetProps {

}

export default function ProductDetailBottomSheet(props: ProductDetailBottomSheetProps) {
  const { open, product } = useAppSelector(root => root.bottomSheet);
  const { name } = product as Product || {};
  const { language } = useLanguage();
  const dispatch = useAppDispatch();
  const sheetRef = useRef<BottomSheetRef>(null);
  return <BottomSheet
    open={open}
    ref={sheetRef}
    expandOnContentDrag
    onDismiss={() => {
      dispatch(closeBottomSheet());
    }}
  >
    <div className={`h-fit w-full bg-red-200 p-4 flex flex-col`}>
      <span>{name?.[language]}</span>
      {/*<VariantSelection />*/}
    </div>
  </BottomSheet>;
}
