import { useCallback, useRef, useEffect, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import SizeItem from "../../containers/drawer/views/SizeItem";
import { CategoryProduct, convertContentToDisplaySize, Product, SelectedTopping, SizeType } from "../../models/Product";
import Button from "../../components/button";
import { calculateItemTotalPrice, formatCurrency } from "../../utils/ConcurencyUtils";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { addItem, setOrder } from "../../store/orderSlice";
import { createOrderDefault } from "../../utils/OrderUtils";
import { useLanguage } from "../../hooks/useLanguage";
import { ProductItem } from "../../store/type";
import VariantSelection from "../../components/bottom-sheet/VariantSelection";
import ChevronLeft from "../../assets/icons/chevron-left";
import HeartIcon from "../../assets/icons/heart-icon";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import { Gap } from "../../components/gap/Gap";
import { scrollContent } from "../../utils/Helper";
import { useGetMenuItemQuery, useGetMenuQuery } from "../../store/api/menuApi";
import { DEFAULT_COUNTRY, DEFAULT_LOCATION, DEFAULT_MENU } from "../../store/sessionSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Skeleton } from "antd";

const INIT_ICE_PERCENT = 100;
const INIT_SUGAR_PERCENT = 100;

export interface ProductDetailPageProps {

}

export function ProductDetailPage() {
  const { product_id } = useParams();
  const osRef = useRef<any>();
  const [loadingImage, setLoadingImage] = useState(true);
  const { data: product = {}, error, isLoading, isSuccess } = useGetMenuItemQuery({
    country: DEFAULT_COUNTRY,
    location: DEFAULT_LOCATION,
    version: DEFAULT_MENU,
    item_id: product_id
  });
  console.log("=>(ProductDetailPage.tsx:39) product", product);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isFavourite, setIsFavourite] = useState(false);
  const currentOrder = useAppSelector(root => root.order.currentOrder);
  const session_id = useAppSelector(root => root.session.session_id);

  useEffect(() => {
    if (!currentOrder) dispatch(setOrder(createOrderDefault({
      session_id,
      user_id: null
    })));
  }, [currentOrder]);

  const { language, t } = useLanguage();

  const { id, image, name, pricing, customizable } = product || {};

  const { hot, sugar, ice, topping } = customizable || {};

  const isHaveIce = !!ice && ice !== null;

  const [totalPrice, setTotalPrice] = useState(0);

  const [selectedSize, setSelectedSize] = useState<SizeType>("small");
  const selectedPrice = pricing?.find((item) => item.size === selectedSize);

  const [icePercent, setIcePercent] = useState(50);
  const [sugarPercent, setSugarPercent] = useState(INIT_SUGAR_PERCENT);
  const [selectedToppings, setSelectedToppings] = useState<SelectedTopping[]>(
    []
  );

  const [isHot, setIsHot] = useState(!isHaveIce);

  useEffect(() => {
    setIsHot(!isHaveIce);
  }, [isHaveIce]);


  useEffect(() => {
    setTotalPrice(calculateItemTotalPrice(product as Product, selectedSize, selectedToppings));
  }, [selectedToppings, selectedSize, product]);

  const addToCart = useCallback(() => {
    const { id, image, name } = product || {};

    const price = pricing?.find((item) => item.size === selectedSize);
    const { value = 0 } = price || {};

    const toppingTotalPrice =
      selectedToppings?.reduce<number>((result, toppingItem) => {
        const { price = 0 } = toppingItem || {};

        return result + price;
      }, 0) || 0;

    const willAddToppings = selectedToppings.map(toppingItem => ({
      item_id: toppingItem.id,
      size: toppingItem.size,
      name: toppingItem.name
    }));

    const itemWillAdd = {
      id: `${id}-${Date.now()}`,
      item_id: id,
      size: selectedSize,
      quantity: 1,
      customizable_hot: isHot,
      customizable_ice: icePercent,
      customizable_sugar: sugarPercent,
      children: willAddToppings,
      name: name?.[language as string],
      total_price: value + toppingTotalPrice || 0,
      price: value,
      image: image
    };

    dispatch(addItem(itemWillAdd as ProductItem));
    navigate(-1);

  }, [isHot, icePercent, sugarPercent, selectedSize, selectedToppings]);

  const onChangeHotStatus = (_, checked: boolean) => {
    setIsHot(checked);
  };

  const onChangeSize = (value) => {
    setSelectedSize(value);
  };

  return <div className="relative flex h-screen w-full flex-col">
    <ScrollToTop />
    <OverlayScrollbarsComponent
      options={{
        scrollbars: {
          autoHideDelay: 250,
          autoHide: "scroll"
        }
      }}
      ref={osRef} className="flex-grow scrollbar-hide">
      <div className={`relative`}>
        <div className="absolute left-0 right-0 p-4 flex flex-row w-full justify-between">
          <button
            className={`w-9 h-9 flex flex-col justify-center items-center rounded-full bg-white`}
            onClick={() => {
              navigate(-1);
            }}
            aria-label="close"
          >
            <ChevronLeft />
          </button>
          <button
            className={`w-9 h-9 flex flex-col justify-center items-center rounded-full
             ${isFavourite ? "bg-secondary-7" : "bg-white"}`}
            onClick={() => setIsFavourite(!isFavourite)}
            aria-label="close"
          >
            <HeartIcon color={isFavourite ? "#fff" : "#858585"} />
          </button>
        </div>

        {isLoading ? <Skeleton.Node active style={{ height: 354, width: "100vw" }} /> :
          <img
            className="object-center w-screen h-[354px] object-cover"
            src={image}
            alt={`${name}-img`}
          />
        }
      </div>
      <div className="flex flex-col p-4 w-full">
        <span className="m-0 text-[20px] font-semibold">{name?.[language]}</span>
        <span className="text-[18px] font-bold text-brand-1">{formatCurrency(+`${selectedPrice?.value}`)}</span>
        <div className="mb-4 w-full flex flex-col items-start">
          <div className="w-full flex flex-row justify-between px-10">
            {pricing?.map((size) => {
              return (
                <SizeItem
                  key={size.size}
                  label={`${convertContentToDisplaySize(size.size)} - ${size.value / 1000}k`}
                  handleChange={onChangeSize}
                  value={size.size}
                  checked={selectedSize === size.size}
                />
              );
            })}
          </div>
          <div className={`w-full mt-4`}>
            <p className={`my-2 text-[14px] font-bold`} id={`iceGapID`}>Hot/ Iced</p>
            <VariantSelection
              icePercent={icePercent}
              setIcePercent={setIcePercent}
              isHot={isHot}
              setIsHot={setIsHot}
              scrollToIceSelector={() => {
                scrollContent(osRef, "iceGapID");
              }}
            />
          </div>
          <div className="mb-4" />
        </div>
      </div>
      <Gap size={"L"} />
    </OverlayScrollbarsComponent>

    <div className="absolute bottom-4 left-0 right-0 flex flex-col px-40px">
      <Button className="w-full bg-brand rounded-[40px] h-[44px]" onClick={addToCart}>
        {`${t("addToCart")} (${formatCurrency(totalPrice)})`}
      </Button>
    </div>
  </div>;
}
