import { formatCurrency } from "../../utils/ConcurencyUtils";
import ChevronRight from "../../assets/icons/chevron-right";
import { useLanguage } from "../../hooks/useLanguage";

export interface TotalPriceSectionProps {
  className?: string;
  total?: number;
  subTotal?: number;
  discount?: number;
}

const Divide = () => (<div className={`w-full h-[1px] bg-divide`} />);

export default function TotalPriceSection(props: TotalPriceSectionProps) {
  const { total = 0, subTotal = 0, discount = 0 } = props || {};
  const { t } = useLanguage();

  return <div className={`flex flex-col bg-white p-4 rounded-lg ${props.className || ""}`}>
    <div>
      <p className={`text-16px font-bold text-brand-4`}>{t("totalSection")}</p>
    </div>
    <div>
      <div className="flex flex-row justify-between py-2">
        <p className={`text-14px font-normal text-tertiary`}>{t("inMoney")}</p>
        <p>{formatCurrency(subTotal)}</p>
      </div>
      <Divide />
      <div className="flex flex-row justify-between py-2">
        <p className={`text-14px font-normal text-tertiary`}>{t("discount")}</p>
        <div className={"flex flex-row justify-between items-center"}>
          {discount > 0 && (<p className={`mr-3`}>{`-${discount}`}</p>)}
          <ChevronRight color="#9F2B2B" width={"10px"} height={"12px"} />
        </div>
      </div>
      <Divide />
      <div className="flex flex-row justify-between pt-2">
        <p className={`text-14px text-brand-4 font-semibold`}>{t("totalNeedPay")}</p>
        <p className={`text-14px text-brand-1 font-semibold`}>{formatCurrency(total)}</p>
      </div>
    </div>
  </div>;
}
