import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from "./locales/en.json";
import viJSON from "./locales/vi.json";
import LocalStorageBackend from "i18next-localstorage-backend";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enJSON },
    vn: { translation: viJSON },
  },
  fallbackLng: "vn",
  backend: {
    backends: [LocalStorageBackend],
    backendOptions: [
      {
        expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
      },
      {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    ],
  },
});
