import React, { useEffect, useRef, useState } from "react";
import useVnpayCallbackParams from "../../hooks/useVnpayCallbackParams";
import {
  responseCodeDescriptions,
  transactionStatusDescriptions,
  VnpayTransactionStatus
} from "../../helpers/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hook";
import { clearOrder } from "../../store/orderSlice";
import { isEmpty } from "lodash";
import { useValidatePaymentMutation } from "../../store/api/consumerApi";
import { s3ImageUrl } from "../../utils/FileUtils";
import { useLanguage } from "../../hooks/useLanguage";

const PaymentResultPage: React.FC = () => {
  const location = useLocation();
  const { t } = useLanguage();
  const vnpayCallbackParams = useVnpayCallbackParams();
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const isPaymentSuccess = vnpayCallbackParams.vnp_TransactionStatus === VnpayTransactionStatus.Success;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isNotInPaymentProgress = isEmpty(vnpayCallbackParams.vnp_TransactionStatus);
  const [validatePayment] = useValidatePaymentMutation();
  const updatePaymentRef = useRef(false);

  useEffect(() => {
    // Update payment status to BE
    if (vnpayCallbackParams?.vnp_TransactionStatus && !updatePaymentRef.current) {
      updatePaymentRef.current = true;
      validatePayment(location.search.substring(1));
    }
  }, [vnpayCallbackParams]);

  return (
    <div className="flex flex-col p-4 xl:pt-20 justify-center items-center h-screen">
      <p
        className={`font-semibold text-24px text-brand-4 text-center whitespace-pre-line`}>{isPaymentSuccess ? t("orderSuccessTitle") : t("orderFailedTitle")}</p>
      {!isPaymentSuccess && (<p
        className={`font-semibold text-14px text-neutrals-grey text-center mt-2 whitespace-pre-line`}>{t("orderFailedSubtitle")}</p>)}
      <img src={s3ImageUrl(isPaymentSuccess ? "san_order_success.png" : "san_order_failed.png")} alt="" />
      <button className={`border-solid py-3 w-full bg-brand-1 mt-4 border-2 rounded-[40px]`} onClick={() => {
        if (isPaymentSuccess) {
          dispatch(clearOrder());
          navigate("/");
        } else {
          // re-order
        }
      }}>
        <p className={`font-semibold text-14px text-white`}>{isPaymentSuccess ? t("home") : t("reorder")}</p>
      </button>
      <button className={`border-solid py-3 w-full bg-white mt-2 border-2 rounded-[40px]`} onClick={() => {
        if (isPaymentSuccess) {
          // check order and bill
          navigate("/order-detail");
          dispatch(clearOrder());
        } else {
          // navigate to Home
          navigate("/");
        }
      }}>
        <p
          className={`font-semibold text-14px text-brand-4`}>{isPaymentSuccess ? t("checkBillDetail") : t("backToHome")}</p>
      </button>
      <p
        className={`font-normal text-12px mt-6 text-tertiary text-center whitespace-pre-line`}>{t("registerSuggestion")}</p>
      <button>
        <p className={`font-semibold text-12px mt-2 text-brand-1 text-center`}>{t("registerAccount")}</p>
      </button>
    </div>
  );
};

export default PaymentResultPage;
