import React, { useState, useRef, useEffect } from "react";
import type { RadioChangeEvent } from "antd";
import { Radio, Space, ConfigProvider } from "antd";

export interface RadioSelectorProps {
  items?: {
    value: any;
    label: string;
    description?: string;
  }[];
  value?: any;
  onChange?: (e: RadioChangeEvent) => void;
}

export default function RadioSelector(props: RadioSelectorProps) {
  const ref = useRef<any>(null);
  const { items = [], value, onChange } = props || {};
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(ref.current?.offsetWidth - 24);
  }, [ref.current]);

  return <div>
    <ConfigProvider theme={{
      token: {
        colorPrimary: "#9F2B2B"
      }
    }}>
      <Radio.Group onChange={onChange} className={`w-full`} value={value}>
        <Space ref={ref} direction="vertical" className={`w-full`}>
          {items.map((item, index) => (
            <div key={`${index}-${item.value}-radio`} className={``}>
              <Radio checked={value === item.value} value={item.value} className={`flex flex-row w-full justify-start`}>
                <div style={{ width: width }}
                     className="flex flex-row w-full justify-between">
                  <span>{item.label}</span>
                  {}
                </div>
              </Radio>
              {index < items.length - 1 && <div className={`w-full mt-2 h-[1px] bg-[#E0E0E0]`} />}
            </div>
          ))}
        </Space>
      </Radio.Group>
    </ConfigProvider>
  </div>;
}
