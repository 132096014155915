import { Product } from "../../models/Product";
import { useAppDispatch } from "../../store/hook";
import { openProductDetail } from "../../store/bottomSheetSlice";
import { useLanguage } from "../../hooks/useLanguage";
import { useNavigate } from "react-router-dom";

export interface MenuProductItemProps {
  product: Product;
}

export default function MenuProductItem(props: MenuProductItemProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { product } = props;
  const { language } = useLanguage();
  const { image, name, pricing, id } = product || {};
  const goToProductDetailPage = () => {
    navigate(`/product/${id}`);
  }
  return <div className="w-[148px] mt-4 ml-4">
    <div
      onClick={goToProductDetailPage}
      className="w-[148px] h-[168px] rounded-[16px] overflow-hidden">
      <img src={image} className={`w-[148px] h-[168px] object-cover`} alt="" />
    </div>
    <div
      onClick={goToProductDetailPage}
      className={`w-full mt-2 flex flex-row justify-between`}>
      <span className={`w-3/4 line-clamp-1 text-brand-4 text-14px font-normal`}>{name?.[language]}</span>
      <span className={`text-brand-1 text-14px font-normal`}>{pricing?.[0].display}</span>
    </div>
    <button
      onClick={() => {
        dispatch(openProductDetail(product));
      }}
      className={`mt-2 bg-white border-brand-shape-3 rounded-[40px] border-solid border-[1px] w-full py-1 hover:bg-brand-shape-3 focus:outline-none"`}>
      Order
    </button>
  </div>;
}
