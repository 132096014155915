import { Product, SelectedTopping, SizeType } from "../models/Product";

export const formatCurrency = (
  amount: number,
  currency = "VND",
  locale = "it-IT",
) => {
  const formatter = new Intl.NumberFormat(locale, {
    currency: currency,
    style: "currency",
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

export const calculateItemTotalPrice = (item: Product, selectedSize: SizeType, selectedToppings: SelectedTopping[]) => {
  const { pricing} = item || {};

  const price = pricing?.find?.((item) => item.size === selectedSize);
  const { value = 0 } = price || {};

  const toppingTotalPrice =
    selectedToppings?.reduce<number>((result, toppingItem) => {
      const { price = 0 } = toppingItem || {};
      return result + price;
    }, 0) || 0;
  return value + toppingTotalPrice;
}
