import { CategoryProduct, Product } from "models/Product";
import React, { useReducer, createContext } from "react";

interface DrawerContextValue {
  showDetails: boolean;
  showCart: boolean;
  showCheckout: boolean;
  showOrders: boolean;
  menu: boolean;
  open: boolean;
  item: Product;
  allToppings?: CategoryProduct;
}

const INITIAL_STATE: DrawerContextValue = {
  showDetails: false,
  showCart: false,
  showCheckout: false,
  showOrders: false,
  menu: false,
  open: false,
  item: {
    id: "",
    image: "",
    type: "",
    name: {
      vn: "",
      en: "",
    },
    customizable: {
      sugar: undefined,
      ice: undefined,
      hot: undefined,
    },
    pricing: [],
    public: false,
  },
};

export const DrawerContext = createContext<{
  state: DrawerContextValue;
  dispatch: React.Dispatch<any>;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

type ActionType =
  | { type: "STORE_PRODUCT_DETAIL"; payload: any }
  | { type: "TOGGLE_PRODUCT_DETAIL"; payload: any }
  | { type: "TOGGLE_CART_VIEW"; payload: any }
  | { type: "TOGGLE_CHECKOUT_VIEW"; payload: any }
  | { type: "TOGGLE_ORDERS_VIEW"; payload: any }
  | { type: "SLIDE_CART"; payload: any }
  | { type: "OPEN_MENU"; payload: any };

type StateType = typeof INITIAL_STATE;

function reducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case "STORE_PRODUCT_DETAIL":
      return {
        ...state,
        item: action.payload.item,
      };
    case "TOGGLE_PRODUCT_DETAIL":
      return {
        ...state,
        showDetails: action.payload.showDetails,
        showCart: false,
        showCheckout: false,
        showOrders: false,
      };
    case "TOGGLE_CART_VIEW":
      return {
        ...state,
        showDetails: false,
        showCart: action.payload.showCart,
        showCheckout: false,
        showOrders: false,
      };
    case "TOGGLE_CHECKOUT_VIEW":
      return {
        ...state,
        showDetails: false,
        showCart: false,
        showCheckout: action.payload.showCheckout,
        showOrders: false,
      };
    case "TOGGLE_ORDERS_VIEW":
      return {
        ...state,
        showDetails: false,
        showCart: false,
        showCheckout: false,
        showOrders: action.payload.showOrders,
      };
    case "SLIDE_CART":
      return {
        ...state,
        open: action.payload.open,
      };
    case "OPEN_MENU":
      return {
        ...state,
        menu: action.payload.menu,
      };

    default:
      return state;
  }
}

export const DrawerProvider = ({ children, allToppings }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <DrawerContext.Provider
      value={{ state: { ...state, allToppings: allToppings }, dispatch }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
