import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order, ProductItem } from "./type";
import { isEqual, sortBy } from "lodash";

interface OrderState {
  currentOrder: Order | null;
}

const initialState: OrderState = {
  currentOrder: null
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      state.currentOrder = action.payload;
    },
    addItem: (state, action: PayloadAction<ProductItem>) => {
      if (state.currentOrder) {
        const existingItem = state.currentOrder.items.find(
          (item) => {
            return item.item_id === action.payload.item_id
              && item.customizable_hot === action.payload.customizable_hot
              && item.customizable_ice === action.payload.customizable_ice
              && item.customizable_sugar === action.payload.customizable_sugar
              && isEqual(sortBy(item.children), sortBy(action.payload.children));
          }
        );

        if (existingItem) {
          existingItem.quantity += action.payload.quantity;
        } else {
          state.currentOrder.items.push(action.payload);
        }
      }
    },
    updateItemQuantity: (state, action: PayloadAction<{ id: string; quantity: number }>) => {
      if (state.currentOrder) {
        const item = state.currentOrder.items.find((item) => item.id === action.payload.id);
        if (item) {
          item.quantity = action.payload.quantity;
        }
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        state.currentOrder.items = state.currentOrder.items.filter((item) => item.id !== action.payload);
      }
    },
    clearOrder: (state) => {
      state.currentOrder = null;
    },
    setExperience: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        state.currentOrder.experience_id = action.payload;
      }
    },
    setDedupeId: (state, action: PayloadAction<string>) => {
      if (state.currentOrder) {
        state.currentOrder.dedup_id = action.payload;
      }
    }
  }
});

export const {
  setOrder,
  addItem,
  updateItemQuantity,
  removeItem,
  clearOrder,
  setExperience,
  setDedupeId
} = orderSlice.actions;

export default orderSlice.reducer;
