const IconBell = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        stroke="#31261D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M4.391 8.125A5.617 5.617 0 0 1 10.043 2.5c3.093.023 5.567 2.594 5.567 5.696v.554c0 2.798.585 4.422 1.1 5.31a.624.624 0 0 1-.537.94H3.827a.625.625 0 0 1-.538-.941c.516-.887 1.102-2.511 1.102-5.309v-.625ZM7.5 15v.625a2.5 2.5 0 0 0 5 0V15"
      />
    </svg>
  );
};

export default IconBell;
