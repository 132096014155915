import * as React from "react";

const IconHome = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        stroke={props?.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M14.25 19.5V15a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 1-.75.75H4.5a.75.75 0 0 1-.75-.75v-8.668a.75.75 0 0 1 .245-.555l7.5-6.819a.75.75 0 0 1 1.009 0l7.5 6.819a.751.751 0 0 1 .246.555V19.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75v0Z"
      />
    </svg>
  );
};
export default IconHome;
