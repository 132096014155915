import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import IconHome from "../assets/icons/icon-home";
import IconGame from "../assets/icons/icon-game";
import IconCoffee from "../assets/icons/icon-coffee";
import IconCart from "../assets/icons/icon-cart";
import IconProfile from "../assets/icons/icon-profile";
import { useAppSelector } from "../store/hook";

const BottomTabBar: React.FC = () => {
  const currentOrder = useAppSelector(root => root.order.currentOrder);
  const orderItem = currentOrder?.items.reduce((acc, current) => {
    return current.quantity + acc;
  }, 0);
  return (
    <div className="fixed flex flex-grow justify-center bottom-4 h-fit left-2 right-2 gap-2">
      <div className="flex center gap-[8px] bg-white rounded-[40px] w-[302px] px-1 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
        <NavBarItem to="/" label="My Sắn" IconComponent={IconHome} />
        <NavBarItem to="/menu" label="Menu" IconComponent={IconCoffee} />
        <NavBarItem badgeCount={orderItem} to="/orders" label="Orders" IconComponent={IconCart} />
        <NavBarItem to="/profile" label="Profile" IconComponent={IconProfile} />
      </div>
      <div
        className="bg-primary w-12 h-12 rounded-[48px] flex justify-center items-center shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
        <IconGame />
      </div>
    </div>
  );
};

type NavItemProps = {
  to: string;
  label: string;
  IconComponent: React.FC<{ className?: string, color: string }>;
  badgeCount?: number;
};

const NavBarItem: React.FC<NavItemProps> = ({ to, label, IconComponent, badgeCount = 0 }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  const itemColor = isActive ? "#8D3D3A" : "#626262";

  return (
    <NavLink
      to={to}
      className={`relative flex flex-col w-fit items-center py-1 transition-all duration-200 ease-in-out`}
    >
      <div className={`flex flex-row items-center justify-center rounded-[40px] py-2 px-[8px] transition-all duration-200 ease-in-out
        ${isActive ? "border-primary border-[1px] w-[102px]" : "border-transparent w-14"}
      `}>
        <IconComponent color={itemColor} />
        {badgeCount > 0 && !isActive && (<div
          className={`absolute top-2 flex flex-row pt-[1px] justify-center items-center right-2 w-[14px] h-[14px] rounded-full bg-brand-1`}>
          <p className={`text-white text-[10px]`}>{badgeCount}</p>
        </div>)}
        <span className={`pt-[2px] ml-1 transition-all duration-200 ease-in-out whitespace-nowrap
          ${isActive ? "opacity-100 translate-y-0 text-primary" : "opacity-0 translate-y-2 hidden"}`}>
          {label}
        </span>
      </div>
    </NavLink>
  );
};

export default BottomTabBar;
