import { useAppSelector } from "../../store/hook";
import { BillItem, Order, ProductItem } from "../../store/type";
import OrderItem from "./OrderItem";
import { useLanguage } from "../../hooks/useLanguage";

export interface MyOrderSectionProps {
  className?: string;
  items?: ProductItem[] | BillItem[];
}

export default function MyOrderSection(props: MyOrderSectionProps) {
  const { items = [] } = props;
  const { t } = useLanguage();
  return <div className={`flex flex-col bg-white p-4 rounded-lg center ${props.className || ""}`}>
    <p className={`text-16px font-bold text-brand-4`}>{t("myOrder")}</p>
    <div>
      {items?.map((item, index) => (<OrderItem key={index} item={item} showDivide={index < items.length - 1} />))}
    </div>
  </div>;
}
