import IconBell from "../../assets/icons/icon-bell";
import IconSearch from "../../assets/icons/icon-search";

type IconWrapperProps = {
  children: JSX.Element;
  onClick?: () => void;
}

const IconWrapper = (props: IconWrapperProps) => {
  return <div
    className={`${props.onClick && "cursor-pointer"}
     bg-white w-10 flex flex-row justify-center items-center pt-1 pl-1 h-10 rounded-full`}
    onClick={props?.onClick}>
    {props.children}
  </div>;
};

export default function Header() {
  return (
    <header
      className="flex w-full items-center pt-4 px-4 flex-row justify-between">
      <span className={`text-black font-semibold text-14px`}>Chào bạn!</span>
      <div className="flex items-center justify-center gap-2">
        <IconWrapper>
          <IconSearch className="w-6 h-6 md:w-6" />
        </IconWrapper>
        <IconWrapper>
          <IconBell className="w-6 h-6 md:w-6" />
        </IconWrapper>
      </div>
    </header>
  );
}
