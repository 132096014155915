import * as React from "react"
const NormalIce = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={70}
    fill="none"
    {...props}
  >
    <path
      stroke={props.color}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M28.328 68.994H13.672a5.704 5.704 0 0 1-5.624-4.733L3.041 25.294H38.96L33.95 64.261a5.712 5.712 0 0 1-5.623 4.733Z"
    />
    <path
      stroke={props.color}
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M39.242 18.496H2.758c-.97 0-1.758.787-1.758 1.758v3.283c0 .97.787 1.758 1.758 1.758h36.484c.97 0 1.758-.787 1.758-1.758v-3.283c0-.97-.787-1.758-1.758-1.758ZM20.996 1.616h-1.083c-9.315 0-16.872 7.557-16.872 16.88H38.96c0-9.323-7.557-16.88-16.88-16.88h-1.083Z"
    />
    <rect
      width={9}
      height={9}
      x={9}
      y={62.138}
      stroke={props.color}
      strokeWidth={2}
      rx={1}
      transform="rotate(-37.842 9 62.138)"
    />
    <rect
      width={9}
      height={9}
      x={25.201}
      y={54.616}
      stroke={props.color}
      strokeWidth={2}
      rx={1}
      transform="rotate(35.301 25.2 54.616)"
    />
    <rect
      width={9}
      height={9}
      x={20}
      y={45.572}
      stroke={props.color}
      strokeWidth={2}
      rx={1}
      transform="rotate(-6.1 20 45.572)"
    />
    <path
      stroke={props.color}
      strokeWidth={2}
      d="m36.016 47.616-5.348 5.95a1 1 0 0 0 .076 1.413l3.756 3.375"
    />
    <rect
      width={9}
      height={9}
      x={13.304}
      y={45.616}
      stroke={props.color}
      strokeWidth={2}
      rx={1}
      transform="rotate(44.467 13.304 45.616)"
    />
  </svg>
)
export default NormalIce
